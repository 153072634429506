import React from 'react'
import Navbar from './navbar'
const Header = () => {
  return (
    <>
       <Navbar/>
    </>
  )
}

export default Header