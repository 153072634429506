import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import belowtestimonial from "../images/belowtestimonial.jpeg";
import RequestQuote from "./RequestQuote";
const Aplatform = () => {
  const itemsData = [
    {
      title: "Request Quote",
      crmLink: {
        scriptSrc: "/RequestQuote",
      }
    }
  ]
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const handleShow = (item) => {
    setSelectedItem(item);
    setShow(true);
  };
  const handleClose = () => setShow(false);
  //const navigate = useNavigate();
  // const scrollToLetsSection = (e) => {
  //   const letsSection = document.getElementById('lets');
  //   if (letsSection) {
  //     letsSection.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };
  return (

    <>
      <section className="aplatform">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-6">
              <figure className="line-left">
                <span>
                  <img src={belowtestimonial} alt="belowtestimonial" />
                </span>
              </figure>
            </div>
            <div className="col-md-6">
              <div className="box">
                <h2>
                  A platform designed to
                  <br /> elevate your coworking
                  <br /> experience.
                </h2>
                <p>
                  Get a <b className="orange">day pass at just 499</b> for a
                  taste
                  <br /> of tomorrow's workspace.
                </p>
                {itemsData.map((item, index) => (
                  <span key={index} onClick={() => handleShow(item)} className="link" style={{ cursor: 'pointer' }}>
                    Request a quote
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {selectedItem && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>{selectedItem ? selectedItem.title : "Modal Title"}</Modal.Title>
            <Button variant="secondary" onClick={handleClose}>X</Button>
          </Modal.Header>
          <Modal.Body>
            {selectedItem.crmLink.scriptSrc === "/RequestQuote" && (
              <RequestQuote isOpen={show} onModalClose={handleClose} />
            )}
          </Modal.Body>
          
        </Modal>
      )}
    </>
  );
};

export default Aplatform;
