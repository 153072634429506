import React from "react";
import Rectangle31 from "../images/MgRectangle31.jpg";
import Rectangle30 from "../images/SohnaNoidaRectangle30.jpg";
import Rectangle32 from "../images/NS3Rectangle32.jpg";
import Union4 from "../images/Union (4).png";
import Group from "../images/Group.png";
import { Link } from "react-router-dom";
const NearBySector62Noida = () => {
  const itemsData = [
    {
      img: Rectangle32,
      heading: "Noida Sector 3",
      desc: "Insightfully built lounges designed to foster creativity and collaboration.",
      imgIcon: Union4,
      descInner: "A - 1, Sector - 3, Noida, Uttar Pradesh PIN– 201301",
      imgGroup: Group,
      descAdd: "Ideally located near dining, shopping, entertainment, and homes to provide an integrated environment for work-life convenience.",
      link: '/coworking-space-in-noida-sectore-3'
    },
    {
      img: Rectangle31,
      heading: "MG Road, Gurugram",
      desc: "Thoughtfully crafted coworking spaces designed to inspire innovation and encourage collaborative synergy.",
      imgIcon: Union4,
      descInner: "Vatika Triangle, Mehrauli - Gurgaon RD, Block B, Sushant Lok, Phase I, Gurugram, Haryana- 122001",
      imgGroup: Group,
      descAdd: "Centrally situated in a vibrant, flourishing neighborhood, our premier office hub facilitates professional growth and connectivity as the nucleus of a thriving community.",
      link: '/coworking-space-in-gurugram-mg-road'
    },
    {
      img: Rectangle30,
      heading: "Sohna Road, Gurugram",
      desc: "Architecturally crafted spaces, empower diverse talents to collaborate, innovate, and thrive.",
      imgIcon: Union4,
      descInner: "4th floor, Block-1, VATIKA BUSINESS PARK, Badshahpur Sohna Rd Hwy, Vatika City, Block W, Sector 49, Gurugram, Haryana 122018",
      imgGroup: Group,
      descAdd: "Our premier office hub, located in a growing area, provides unmatched convenience by linking work, food and beverage, fun, and living spaces.",
      link: '/coworking-space-in-gurugram-sohna-road'
    },
  ];
  window.scrollTo(0, 0);
  return (
    <>
      <section className="news Nearcentres">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center Nearby">
              <h2>Nearby centres</h2>
              <ul>
                {itemsData.map((item, index) => (
                  <li key={index}>
                    <Link to={item.link}>
                      <div className="box">
                        <figure>
                          <span>
                            <img src={item.img} alt={`Rectangle${index + 30}`} />
                          </span>
                        </figure>
                        <div className="text">
                          <h5>{item.heading}</h5>
                          <p>{item.desc}</p>
                          <ul>
                            <li>
                              <span>
                                <img src={item.imgIcon} height="25px" width="25px" alt="Union4" />
                              </span>
                              {item.descInner}
                            </li>
                            <li>
                              <span>
                                <img
                                  src={item.imgGroup}
                                  height="20px"
                                  width="20px"
                                  alt='imggroup'
                                />
                              </span>
                              {item.descAdd}
                            </li>
                          </ul>
                          <Link to={item.link} className="link nearby">Read More</Link>
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
              
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NearBySector62Noida;
