import React from 'react'
import Rectangle32 from "../images/coming-soon.jpg";
const ComingSoon = () => {
  window.scrollTo(0, 0);
  return (
    <>
    <section className='comingsoon'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                <img src={Rectangle32} alt={`RectangleRectangle32`} />
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default ComingSoon