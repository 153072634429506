import React, { useState, useEffect } from 'react';
import withMeta from "./withMeta.js";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { OFFICE_LOCATION_API_URL,MEDIA_API_URL } from '../constants/api';
const CoworkingSpaces = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(OFFICE_LOCATION_API_URL, {
                    params: {
                        taxonomy: 'category',
                        categories: [10, 11]
                    },
                });
                const category10Data = [];
                const category11Data = [];
                await Promise.all(response.data.map(async post => {
                    const imageUrl = await getImageUrl(post.acf.image);
                    const postData = {
                        ...post.acf,
                        imageUrl,
                    };
                    if (post.categories.includes(10)) {
                        category10Data.push(postData);
                    } else if (post.categories.includes(11)) {
                        category11Data.push(postData);
                    }
                }))
                //console.log('Category 10 Data:', category10Data);
                //console.log('Category 11 Data:', category11Data);
                //const locationsData = response.data.map(post => post.acf);
                //setData(locationsData);
                setData({ category10Data, category11Data });
            } catch (error) {
                console.error('Error Fetching Data:', error);
            }
        };
        window.scrollTo(0, 0);
        fetchData();
    }, []);
    const getImageUrl = async (attachmentId) => {
        try {
            const imageResponse = await axios.get(`${MEDIA_API_URL}/${attachmentId}`);
            return imageResponse.data.source_url;
        } catch (error) {
            console.error(`Error fetching image URL for attachment ID ${attachmentId}:`, error);
            return null;
        }
    };
    return (
        <>
            <div className="banner" id="bannerCoworkingSpaces">
                <div className="container">
                    <div className="row">
                        <h1>Coworking <span>Spaces</span></h1>
                    </div>
                </div>
            </div>
            <section className="osyc">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Coworking Spaces</h2>
                            <p>Discover premium coworking spaces that take your work to the next level! Our spaces, which are strategically located in the successful micromarkets of Noida and Gurugram, provide a dynamic environment conducive to innovation.</p>
                            <p>Experience ultimate flexibility with customizable office spaces designed to meet the diverse needs of modern businesses. Whether you're a startup or large corporation, our Grade A commercial real estate provides the perfect backdrop for your success.</p>
                            <p>Enjoy the convenience of our prime locations, effortlessly connecting you to major business hubs across both cities. Surround yourself with vibrant communities of changemakers, creators, and visionaries united in collaborative workspaces.</p>
                            <p>Build up your work experience with features such as high-speed WiFi, conference rooms, private pods, and more. Our advanced coworking spaces effortlessly combine productivity, comfort, and inspiration.</p>
                            <p>If you're looking for the ideal office space in Noida or Gurugram, look no further. Join our coworking community and unlock your potential today! The future of work is here.</p>
                            <ul>
                                <li>
                                    <div className="box">
                                        <h5 className=""><span></span> 4</h5>
                                        <h5 className="opacity">Centers</h5>
                                    </div>
                                </li>
                                <li>
                                    <div className="box">
                                        <h5 className=""><span></span> 1.6Lac + sqft</h5>
                                        <h5 className="opacity">Area</h5>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="osc news coworkingspaces">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>Ofis Square In Gurugram</h2>
                        </div>
                        <div className="col-md-12 text-center">
                            <ul>
                                {Array.isArray(data.category11Data) && data.category11Data.map((location, index) => (
                                    <li key={index}>
                                        <Link to={location.page_link}>
                                            <div className="box">
                                                <figure>
                                                    <span className="Ofisimg">
                                                        <img src={location.imageUrl} alt={`Office Location ${index + 1}`} />
                                                    </span>
                                                </figure>
                                                <div className="text">
                                                    <h5 className="text-center">{location.title}</h5>
                                                    <ul className="ofis-box">
                                                        <li>
                                                            <p><span><img src="images/Vector1 (4).png" alt="vector1" height="15" width="15" /></span> {location.desk_number} Desks</p>
                                                        </li>
                                                        <li>
                                                            <p><span><img src="images/Vector2 (3).png" height="20" width="20" alt="vector2" /></span>{location.square_feet} SqFt</p>
                                                        </li>
                                                        <li>
                                                            <p><span><img src="images/metro-station.png" height="20" width="20" alt="metro" /></span>{location.location_center}</p>
                                                        </li>
                                                        <li>
                                                            <p><span><img src="images/Vector4 (1).png" height="20" width="20" alt="vector4" /></span>{location.distance_from_airport} KM from airport</p>
                                                        </li>
                                                    </ul>
                                                    <p className="osctext">Choose a workspace solution that suits your business</p>
                                                    <ul className="osclastcol">
                                                        {location.workspace_ssolution && Object.keys(location.workspace_ssolution).map((key, index) => (
                                                            <li key={index}>
                                                                {typeof location.workspace_ssolution[key] === 'object' ? (
                                                                    location.workspace_ssolution[key].business_suits
                                                                ) : (
                                                                    location.workspace_ssolution[key]
                                                                )}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <Link to={location.page_link} className="link text-center">Read More</Link>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                ))}

                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="osc news coworkingspaces">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>Ofis Square in Noida</h2>
                        </div>
                        <div className="col-md-12 text-center">
                            <ul>
                                {Array.isArray(data.category10Data) && data.category10Data.map((location, index) => (
                                    <li key={index}>
                                        <Link to={location.page_link}>
                                            <div className="box">
                                                <figure>
                                                    <span className="Ofisimg">
                                                        <img src={location.imageUrl} alt={`Office Location ${index + 1}`} />
                                                    </span>
                                                </figure>
                                                <div className="text">
                                                    <h5 className="text-center">{location.title}</h5>
                                                    <ul className="ofis-box">
                                                        <li>
                                                            <p><span><img src="images/Vector1 (4).png" alt="vector1" height="15" width="15" /></span> {location.desk_number} Desks</p>
                                                        </li>
                                                        <li>
                                                            <p><span><img src="images/Vector2 (3).png" height="20" width="20" alt="vector2" /></span>{location.square_feet} SqFt</p>
                                                        </li>
                                                        <li>
                                                            <p><span><img src="images/metro-station.png" height="20" width="20" alt="metro" /></span>{location.location_center}</p>
                                                        </li>
                                                        <li>
                                                            <p><span><img src="images/Vector4 (1).png" height="20" width="20" alt="vector4" /></span>{location.distance_from_airport} KM from airport</p>
                                                        </li>
                                                    </ul>
                                                    <p className="osctext">Choose a workspace solution that suits your business</p>
                                                    <ul className="osclastcol">
                                                        {location.workspace_ssolution && Object.keys(location.workspace_ssolution).map((key, index) => (
                                                            <li key={index}>
                                                                {typeof location.workspace_ssolution[key] === 'object' ? (
                                                                    location.workspace_ssolution[key].business_suits
                                                                ) : (
                                                                    location.workspace_ssolution[key]
                                                                )}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <Link to={location.page_link} className="link text-center">Read More</Link>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                ))}

                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default withMeta("CoworkingSpaces")(CoworkingSpaces);