import React, { useState } from 'react'
import withMeta from "./withMeta.js";
import { Modal, Button } from 'react-bootstrap';
import Rectangle49 from "../images/managedworkspaces.jpg";
import Rectangle49bfe from "../images/bfe.png";
import Rectangleteam from "../images/team.png";
import Rectanglecew from "../images/cew.jpg";
import Rectanglewco from "../images/wco.jpg";
import Lets from './Lets';
import AboutTrustedCompanies from './AboutTrustedCompanies';
import EnquireNow from './EnquireNow';
const ManagedOffices = () => {
    const [expandedItems, setExpandedItems] = useState([]);
    const [show, setShow] = useState(false);
    const handleShow = () => {
        setShow(true);
    };
    const handleClose = () => setShow(false);
    const itemsbfe = [
        {
            heading: "Custom Spaces For Your Enterprise",
            desc: "Our enterprise-grade office spaces offer unparalleled quality designed for businesses of all sizes.",
        }
    ];
    // const itemsData = [
    //     {
    //         img: Rectangleteam,
    //         heading: "Cody Fisher",
    //         desc: "Co-Founder & CEO",
    //     },
    //     {
    //         img: Rectangleteam,
    //         heading: "Cody Fisher",
    //         desc: "Co-Founder & CEO",
    //     },
    //     {
    //         img: Rectangleteam,
    //         heading: "Cody Fisher",
    //         desc: "Co-Founder & CEO",
    //     },
    //     {
    //         img: Rectangleteam,
    //         heading: "Cody Fisher",
    //         desc: "Co-Founder & CEO",
    //     },
    //     {
    //         img: Rectangleteam,
    //         heading: "Cody Fisher",
    //         desc: "Co-Founder & CEO",
    //     },
    //     {
    //         img: Rectangleteam,
    //         heading: "Cody Fisher",
    //         desc: "Co-Founder & CEO",
    //     },
    //     {
    //         img: Rectangleteam,
    //         heading: "Cody Fisher",
    //         desc: "Co-Founder & CEO",
    //     },
    //     {
    //         img: Rectangleteam,
    //         heading: "Cody Fisher",
    //         desc: "Co-Founder & CEO",
    //     },
    //     {
    //         img: Rectangleteam,
    //         heading: "Cody Fisher",
    //         desc: "Co-Founder & CEO",
    //     },
    //     {
    //         img: Rectangleteam,
    //         heading: "Cody Fisher",
    //         desc: "Co-Founder & CEO",
    //     },
    //     {
    //         img: Rectangleteam,
    //         heading: "Cody Fisher",
    //         desc: "Co-Founder & CEO",
    //     },
    //     {
    //         img: Rectangleteam,
    //         heading: "Cody Fisher",
    //         desc: "Co-Founder & CEO",
    //     },
    // ];
    const itemscew = [
        {
            heading: "Cost Effective Workspaces",
            desc: "Our managed offices provide complete customization while optimizing your real estate costs. We collaborate with you to design spaces aligned to your workspace needs and preferences. Whether you need customized offices in Bangalore, Mumbai, Chennai, Hyderabad, Delhi-NCR or Pune, we are your premium workspace solution. Our expertly designed spaces cater to your specific requirements, providing a seamless environment for your business to thrive. Discover our premium offices today.",
        }
    ];
    const listItems = [
        "100% Compliant, Premium Grade A Real Estate (Compliant with NBC guidelines)",
        "Centres across prime properties provide stable support for long-term business growth",
        "Enjoy Grade ‘A’ campuses with services like events, facility management, security, and parking",
        "End-to-end workspace solutions including design, build and management of custom environments",
        "In-house expertise in designing, developing and managing workspaces customized for your business needs",
    ];
    const handleReadMoreClick = (index, event) => {
        event.preventDefault();
        const updatedExpandedItems = [...expandedItems];
        updatedExpandedItems[index] = !updatedExpandedItems[index];
        setExpandedItems(updatedExpandedItems);
    };
    window.scrollTo(0, 0);
    return (
        <>
            <section className="passion">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>Managed Workspaces, Designed For Your Success</h1>
                            <img src={Rectangle49} alt='Rectangle49' />
                        </div>
                    </div>
                </div>
            </section>
            <section className="esy pt-0">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-5 p-0">
                            <h2>Customized Offices <br />To Seamlessly Support Your Business</h2>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="box">
                                <p>Experience the flexibility of a customized office designed around your unique needs. We provide end-to-end management to streamline your workplace and maximize productivity.</p>
                                <span onClick={() => handleShow()} className="link">Enquire Now</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bfe">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        {itemsbfe.map((item, index) => (
                            <div className="col-md-8" key={index}>
                                <h2>{item.heading}</h2>
                                <p>{expandedItems[index] ? item.desc : `${item.desc.slice(0, 100)}...`}</p>
                                <div onClick={(event) => handleReadMoreClick(index, event)}>
                                    {expandedItems[index] ? "Read less" : "Read more"}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <img src={Rectangle49bfe} alt='Rectangle49bfe' />
                        </div>
                    </div>
                </div>
            </section>
            <section className="cew">
                <div className="container">
                    <div className="row align-items-center rev">
                        {itemscew.map((item, index) => (
                            <div className="col-md-6" key={index}>
                                <h2 dangerouslySetInnerHTML={{ __html: item.heading }} />
                                <p>{item.desc}</p>
                                <span onClick={() => handleShow()} className="link">Enquire Now</span>
                            </div>
                        ))}
                        <div className="col-md-6">
                            <img src={Rectanglecew} alt='Rectanglecew' />
                        </div>
                    </div>
                </div>
            </section>
            <section className="wco pt-0">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="boximg">
                                <img src={Rectanglewco} alt='Rectanglewco' />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="box">
                                <h2>Why Choose Ofissquare</h2>
                                <ul>
                                    {listItems.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                                <span onClick={() => handleShow()} className="link">Enquire Now</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mosaag">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-md-8">
                            <h2>Managed Office Solutions at a Glance</h2>
                            <p>Learn more about our customised offices and how we can help you simplify your workspace operations.</p>
                        </div>
                        <div className="col-md-12">
                            <iframe src="https://www.youtube-nocookie.com/embed/QEnPRQ3FC1Y?si=frTBczhON1jDCtfS&amp;start=10" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullScreen></iframe>
                        </div>
                    </div>
                </div>
            </section>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>ENQUIRE NOW</Modal.Title>
                    <Button variant="secondary" onClick={handleClose}>X</Button>
                </Modal.Header>
                <Modal.Body>
                    <EnquireNow isOpen={show} onModalClose={handleClose} />
                </Modal.Body>
            </Modal>
            <AboutTrustedCompanies />
            <Lets />
        </>
    )
}

export default withMeta("ManagedOffices")(ManagedOffices)   