import React from 'react'

const AboutOperations = () => {
    const itemsData = [
        {
            itemNum: '2',
            itemHeading: 'Cities',
            // desc: "We have worked hard to create a refreshing work environment",
        },
        {
            itemNum: '4',
            itemHeading: 'Locations',
            // desc: "We have worked hard to create a refreshing work environment",
        },
        {
            itemNum: '4000+',
            itemHeading: 'Seats',
            // desc: "We have worked hard to create a refreshing work environment",
        },
        {
            itemNum: '2.5 Lac Sq.Ft',
            itemHeading: 'Area Under Management',
            // desc: "We have worked hard to create a refreshing work environment",
        },

    ];
    return (
        <>
            <section className="yyyy aboutuspage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <ul>
                                {
                                    itemsData.map((item, index) => (
                                        <li key={index}>
                                            <div className="box">
                                                <h1 className="orange">{item.itemNum}</h1>
                                                <h6>{item.itemHeading}</h6>
                                                <p>{item.desc}</p>
                                            </div>
                                        </li>

                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutOperations