export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://devmandeep.in/ofsquare/wp-json/wp/v2';
export const POSTS_API_URL = `${API_BASE_URL}/posts`;
console.log("Number posts",POSTS_API_URL.length);
//console.log('Posts ULR:', POSTS_API_URL);
export const USERS_API_URL = `${API_BASE_URL}/users`;
export const CATEGORIES_API_URL = `${API_BASE_URL}/categories`;
export const POSTS_CATEGORIES_API_URL = `${POSTS_API_URL}?categories`;
export const MEDIA_API_URL = `${API_BASE_URL}/media`;
export const POSTS_WITH_EXCLUDED_CATEGORIES_URL = `${POSTS_API_URL}?categories_exclude=8`;
console.log("Number POSTS_WITH_EXCLUDED_CATEGORIES_URL",POSTS_WITH_EXCLUDED_CATEGORIES_URL.length);
export const OFFICE_LOCATION_API_URL = `${API_BASE_URL}/office_location`;
export const NOT_FOUND_IMAGE_URL = `https://ofissquare.com/wpofissquare/wp-content/uploads/2023/12/not-found.png`