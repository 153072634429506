import React from 'react'
import withMeta from "./withMeta.js"
import FaqSector62Noida from './FaqSector62Noida'

const FAQ = () => {
    window.scrollTo(0, 0);
    return (
        <>
            <FaqSector62Noida />
        </>
    )
}

export default withMeta("FAQ")(FAQ)